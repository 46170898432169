import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часто задаваемые вопросы на хабе Bike Bliss
			</title>
			<meta name={"description"} content={"Педалируйте свои вопросы - в хабе Bike Bliss есть ответы"} />
			<meta property={"og:title"} content={"FAQ | Часто задаваемые вопросы на хабе Bike Bliss"} />
			<meta property={"og:description"} content={"Педалируйте свои вопросы - в хабе Bike Bliss есть ответы"} />
			<meta property={"og:image"} content={"https://bakuriser.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bakuriser.com/img/35235235.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bakuriser.com/img/35235235.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bakuriser.com/img/35235235.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bakuriser.com/img/35235235.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bakuriser.com/img/35235235.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bakuriser.com/img/35235235.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-11">
			<Text
				margin="0px 0px 70px 0px"
				font="normal 900 52px/1.2 --fontFamily-sans"
				color="#0e1940"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
				sm-font="normal 900 36px/1.2 --fontFamily-sans"
			>
				Часто задаваемые вопросы
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				lg-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Какие услуги вы предлагаете для велосипедов?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Мы предоставляем широкий спектр услуг: от базового тюнинга и технического обслуживания до комплексного капитального ремонта, сборки на заказ и повышения производительности. Мы обслуживаем все типы велосипедов, гарантируя, что ваше транспортное средство будет в идеальном состоянии.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Проводите ли вы мастер-классы или мероприятия для велосипедистов?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Да, мы проводим различные семинары, направленные на совершенствование навыков езды на велосипеде, технического обслуживания, а также организуем общественные поездки, чтобы познакомиться и насладиться дружеским общением с коллегами-велосипедистами.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Можно ли в хабе Bike Bliss изготовить велосипед на заказ?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Конечно! Мы специализируемся на изготовлении велосипедов на заказ. Наши специалисты вместе с вами спроектируют и соберут велосипед, который будет идеально соответствовать вашему стилю езды, предпочтениям и целям.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Что отличает Bike Bliss Hub от других велосипедных магазинов?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Наша нацеленность на создание гостеприимного общественного центра для велосипедистов, а также наш опыт и страсть к велоспорту отличают нас от других. Мы не просто магазин - мы место назначения для велосипедистов, ищущих качественный сервис и разделяющих любовь к велосипеду.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Сколько времени занимает обслуживание?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Сроки обслуживания могут варьироваться в зависимости от сложности требуемых работ. Мы стремимся к быстрому выполнению работ, как правило, от одного дня для простого тюнинга до нескольких дней для более сложных ремонтов или индивидуальных работ.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Нужно ли мне заранее записываться на обслуживание или мастер-класс?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Несмотря на то, что посетителям всегда рады, мы рекомендуем заранее записываться на услуги и мастер-классы, чтобы быть уверенными, что вы получите время, которое лучше всего подходит для вашего расписания.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Катайтесь с уверенностью, мы вас прикроем
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Если у вас есть вопрос, который мы не осветили, или если вам интересно узнать что-то конкретное о ваших велосипедных потребностях, не стесняйтесь обращаться к нам. В Bike Bliss Hub мы готовы поддержать ваше путешествие на велосипеде на каждом шагу.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://bakuriser.com/img/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});